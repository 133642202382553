import { Injectable, InjectionToken, ErrorHandler, Inject } from '@angular/core';

import Rollbar from 'rollbar';
import { environment } from '../environments/environment';

const rollbarConfig = {
  accessToken: '3936ef9e91cf441a87fd263b13f59237',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: environment.production ? 'production' : 'development',
  enabled: true, //environment.production ? true : false
  autoInstrument: {
    network: true,
    log: true,
    dom: false,
    navigation: false,
    connectivity: true,
    contentSecurityPolicy: true,
    errorOnContentSecurityPolicy: true
  }

};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {

  constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

  handleError(error: any): void {

    this.rollbar.error(error.originalError || error);

  }

}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
