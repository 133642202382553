import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent {

  constructor(private router: Router) { }


  get emailLink(): string {
    return `mailto:appointments@intus.co.nz?subject="failed form link"&body="Failed to find form from link ${this.router.url}`;
  }

}
